import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import { getToken, getTimeTag, getTimeSpan, getNeedValid } from '@/utils/auth'
import { Encrypt } from '@/utils/common'
import layer from 'vue-layer'
import md5 from 'js-md5'

const $layer = layer(Vue)

const service = axios.create({
  baseURL: 'https://mapi.playsstore.cn',
  timeout: 1800000,
  dindex: ''
})
service.interceptors.request.use(
  config => {
    config.isver = config.url.indexOf('/global/') !== -1
    const token = getToken()
    let time = new Date().getTime()
    const timespan = getTimeSpan()
    time = time - timespan
    let tag = ''
    if (token !== '') {
      tag = getTimeTag()
    }
    if (token) {
      config.headers['Authorization'] = token
    }
    config.headers['x-module'] = process.env.MODULE_ID
    config.headers['x-timestamp'] = time
    config.headers['x-sign'] = md5(Encrypt(time + '' + tag))
    config.dindex = config.isver ? '' : $layer.loading({
      type: 3,
      content: 'Loading',
      spinner: 'el-icon-loading'
    })
    // console.log('requst:' + JSON.stringify(config.data))
    return config
  },
  error => {
    if (error.config.dindex) {
      $layer.close(error.config.dindex)
    }
    $layer.msg('连接失败，请检查您的网络设置!', { title: '系统提示', icon: 1 })
    // $layer.alert(error.message, { title: '系统提示1', icon: 1 })
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.config.dindex) {
      $layer.close(response.config.dindex)
    }
    if (res.state === undefined) {
      return response
    }
    if (res.state !== 200) {
      if (response.config.isver) {
        return
      }
      if (res.state === 301 || res.state === 302) {
        $layer.confirm('账号在其他地方登录或者登录超时，请重新登录', '系统提示', () => {
          store.dispatch('FedLogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
        return
      }

      if (!response.config.isver) {
        $layer.msg(res.msg)
      }
      return
    } else {
      // console.log(response.data.data)
      const tokencheck = getToken()
      const needVaildcheck = getNeedValid()
      if ((needVaildcheck === 0) && tokencheck && res.binfover && !response.config.isver && res.binfover !== store.state.app.binfover) {
        store.dispatch('getUpBaseInfo')
      }
      if ((needVaildcheck === 0) && tokencheck && res.tinfover && !response.config.isver && res.tinfover !== store.state.app.tinfover) {
        store.dispatch('getUpTeachInfo')
      }
      if ((needVaildcheck === 0) && tokencheck && res.pinfover && !response.config.isver && res.pinfover !== store.state.app.pinfover) {
        store.dispatch('getUpProductInfo')
      }
      return response.data
    }
  },
  error => {
    if (error.config.isver) {
      return
    }
    if (error.config.dindex) {
      $layer.close(error.config.dindex)
    }
    if (!error.response) {
      $layer.msg('连接失败，请检查您的网络!', { title: '系统提示', icon: 1 })
      return
    }
    if (error.response.status === 50014) {
      $layer.confirm('账号在其他地方登录或者登录超时，请重新登录', '系统提示', () => {
        store.dispatch('FedLogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      })
      return
    }
    if (error.response.status === 413) {
      $layer.msg('上传文件超过限制，请上传2M以内的文件!', { title: '系统提示', icon: 1 })
      return
    }
    $layer.msg(error.message, { title: '系统提示', icon: 1 })
    return
    // return Promise.reject(error)
  }
)

export default service
