<template>
  <div class="dizi" @click="gowai">
     <div>
        浙ICP备2024093847号
     </div>
  </div>
</template>

<script>

export default {
  name: 'bottomList',

  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {
    gowai() {
        window.open('https://beian.miit.gov.cn/')
    }
  },
};
</script>

<style lang="less" scoped>
.dizi{
    font-size: 10rem;
    padding: 10rem 20rem;
    text-align: center;
    div{
        line-height: 20rem;
        color: #666;
    }
}
</style>
